import styles from './LoginInfoSavedModal.module.scss';

export default function LoginInfoSavedModal({ message }) {
  return (
    <div className={styles.loginInfoSavedModal}>
      <h2>Login info saved</h2>
      <p>{message}</p>
    </div>
  );
}
