import { Link } from 'react-router-dom';
import styles from './NotAuthenticatedUserHeader.module.scss';
import { useModal } from '../../context/useModal';
import HeaderModalContent from '../HeaderModalContent/HeaderModalContent';

export default function NotAuthenticatedUserHeader() {
  const { openModal, modalContent } = useModal();
  console.log('modalContent: ', modalContent);

  const handleSignUpClick = () => {
    openModal(<HeaderModalContent />);
  };
  return (
    <div className={styles.container}>
      <Link to={'login'} className={styles.loginLink}>
        <span>Login</span>
      </Link>
      <button onClick={handleSignUpClick} className={styles.signUpLink}>
        Sign Up
      </button>
    </div>
  );
}
