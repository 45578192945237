import React, { useState, useContext, createContext } from 'react';

// Use PascalCase for context (common convention)
const ModalContext = createContext();

export function ProvideModal({ children }) {
  const modal = useProvideModal();
  return (
    <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
  );
}

// Use hook for easy access to the context
export const useModal = () => {
  return useContext(ModalContext);
};

// Hook to provide modal functionality
function useProvideModal() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [variant, setVariant] = useState(null);

  // Optionally add methods here that change these states
  const openModal = (content, variantPassed) => {
    console.log('openModal', content, variantPassed);

    setModalContent(content);
    setShowModal(true);
    if (variantPassed) {
      setVariant(variantPassed);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent(null);
    setVariant(null);
  };

  return {
    showModal,
    setShowModal,
    modalContent,
    setModalContent,
    openModal,
    closeModal,
    variant,
  };
}
