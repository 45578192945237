import styles from './VIPSection.module.scss';
import { handleVIPClick } from '../../utils/CommonUtils';
import CustomButton from '../CustomButton/CustomButton';

export default function VIPSection() {
  return (
    <section className={styles.vipContainer}>
      <h3>Become a VIP</h3>
      <p>
        Become a VIP and get exclusive perks like early access to tickets,
        discounts and more.
      </p>
      <h4>The best of all ... IT'S FREE!!!</h4>
      <CustomButton
        title="Become VIP"
        variant="vip"
        clickHandler={handleVIPClick}
      />
    </section>
  );
}
