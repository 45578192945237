export function getCroppedImg(imageSrc, crop) {
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = url;
    });

  return new Promise(async (resolve, reject) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set the canvas size to the crop size
    canvas.width = crop.width;
    canvas.height = crop.height;

    // Create a circular path for masking
    ctx.beginPath();
    ctx.arc(
      crop.width / 2, // center x of the circle
      crop.height / 2, // center y of the circle
      crop.width / 2, // radius
      0,
      2 * Math.PI
    );
    ctx.closePath();
    ctx.clip(); // Apply the mask
    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      }
      const fileUrl = URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/png');
  });
}
