import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.scss';
import { navigateToPromoter } from '../../utils/NavigationUtils';
import smallLogo from '../../assets/links-logo.svg';
import linkIcon from '../../assets/link-icon.svg';
import connectIcon from '../../assets/connect-icon.svg';
import shareIcon from '../../assets/share-icon.svg';
import ExplanationGroup from '../../components/ExplanationGroup/ExplanationGroup';
import CustomButton from '../../components/CustomButton/CustomButton';
import { getContentForPage } from '../../utils/APIUtils';
import MailList from '../../components/MailList/MailList';
import { useLanguage } from '../../context/useLanguage';
import defaultBgImg from '../../assets/default_event_banner.png';
import { loadImage } from '../../utils/CommonUtils';

// Utility function to check if an image URL is valid
// const loadImage = (src, fallback, callback) => {
//   const img = new Image();
//   img.src = src;
//   img.onload = () => callback(src); // Image loaded successfully
//   img.onerror = () => callback(fallback); // Image failed to load, use fallback
// };

/**
 * A React functional component that renders the home page.
 *
 * @return {JSX.Element} The rendered home page.
 */
export default function Home() {
  const navigate = useNavigate();
  const handleTakeATour = () => {
    console.log('handleTakeATour clicked in Home');
    navigate('/contact-us');
  };

  const [content, setContent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [bgImg, setBgImg] = useState(defaultBgImg);
  const { language } = useLanguage();
  console.log('content => ', content);

  // const imgKey = Object.keys(content) > 0 ? content.heroImg : null; //TODO: PUT IT BACK LATER AND TEST THE IMAGE RETRIEVING
  const imgKey = content.heroImg || null;
  const imgURL = imgKey
    ? `${process.env.REACT_APP_IMG_BASE_URL}${imgKey}/public`
    : '/default_event_banner.png';

  console.log('imgURL => ', imgURL);

  useEffect(() => {
    console.log('imgKey => ', imgKey);
    loadImage(imgURL, defaultBgImg, setBgImg);
  }, [imgKey]);

  /**
   * Navigates to the events page.
   *
   * @return {void} This function does not return anything.
   */
  const navigateToEvents = () => {
    navigate('/events');
  };

  const getFetchedContent = async () => {
    const fetchedContent = await getContentForPage('homepage');
    setContent(fetchedContent);
    // setIsLoading(false);
  };

  useEffect(() => {
    getFetchedContent();
  }, []);

  useEffect(() => {
    if (Object.keys(content).length > 0) {
      setIsLoading(false);
    }
  }, [content]);

  return isLoading ? (
    <div className={styles.home}>
      <div className={styles.loader}>
        <h1>Loading...</h1>
      </div>
    </div>
  ) : (
    <div className={styles.home}>
      <section
        className={styles.banner}
        style={{
          backgroundImage: `linear-gradient(rgba(42, 44, 58, 0.7), rgba(42, 44, 58, 0.7)), url(${bgImg})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className={styles.bannerContainer}>
          <h1>{content.lang[language].banner.bannerTitle}</h1>
          <h4>{content.lang[language].banner.bannerText}</h4>
          <div className={styles.btnContainer}></div>
          <CustomButton
            clickHandler={handleTakeATour}
            title={content.lang[language].banner.bannerButton}
            isPill={true}
            colorScheme="white"
            variant="home"
          />
        </div>
      </section>
      <section className={styles.promotersAndEvents}>
        <div className={styles.promoters}>
          <div className={styles.promotersContainer}>
            <div className={styles.textContainer}>
              <h1>
                {content.lang[language].promotersAndEvents.promoters.title}
              </h1>
              <h4 className={styles.list}>
                {content.lang[language].promotersAndEvents.promoters.subtitle}
              </h4>
              <h6>
                {content.lang[language].promotersAndEvents.promoters.text1}
              </h6>
              <h6>
                {content.lang[language].promotersAndEvents.promoters.text2}
              </h6>
            </div>
            <div className={styles.btnContainer}>
              <CustomButton
                clickHandler={navigateToPromoter}
                title={
                  content.lang[language].promotersAndEvents.promoters
                    .promoterButton
                }
                isPill={true}
                colorScheme="black"
                variant="home"
              />
            </div>
          </div>
        </div>
        <div className={styles.logoContainer}>
          <img
            src={smallLogo}
            alt="Eventlinx Logo"
            className={styles.linksLogo}
          />
        </div>
        <div className={styles.events}>
          <div className={styles.eventsContainer}>
            <div className={styles.textContainer}>
              <h1>{content.lang[language].promotersAndEvents.events.title}</h1>
              <h4 className={styles.list}>
                {content.lang[language].promotersAndEvents.events.subtitle}
              </h4>
              <h6>{content.lang[language].promotersAndEvents.events.text1}</h6>
              <h6>{content.lang[language].promotersAndEvents.events.text2}</h6>
            </div>
            <div className={styles.btnContainer}>
              <CustomButton
                clickHandler={navigateToEvents}
                title={
                  content.lang[language].promotersAndEvents.events.eventButton
                }
                isPill={true}
                variant="home"
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.whyEventlinx}>
        <div className={styles.explanations}>
          <ExplanationGroup
            icon={linkIcon}
            title={content.lang[language].whyEventlinx.group1.title}
            altTxt={content.lang[language].whyEventlinx.group1.altText}
            normalText={
              content.lang[language].whyEventlinx.group1.normalText.text1
            }
            boldText={content.lang[language].whyEventlinx.group1.boldText}
          />
          <ExplanationGroup
            icon={shareIcon}
            title={content.lang[language].whyEventlinx.group2.title}
            altTxt={content.lang[language].whyEventlinx.group2.altText}
            normalText={[
              content.lang[language].whyEventlinx.group2.normalText.text1,
              content.lang[language].whyEventlinx.group2.normalText.text2,
            ]}
            boldText={content.lang[language].whyEventlinx.group2.boldText}
          />
          <ExplanationGroup
            icon={connectIcon}
            title={content.lang[language].whyEventlinx.group3.title}
            altTxt={content.lang[language].whyEventlinx.group3.altText}
            normalText={
              content.lang[language].whyEventlinx.group3.normalText.text1
            }
            boldText={content.lang[language].whyEventlinx.group3.boldText}
          />
        </div>
      </section>

      <section className={styles.phrase}>
        <h2 className={styles.quote}>{content.lang[language].phrase.quote}</h2>
        <h2>{content.lang[language].phrase.text}</h2>
      </section>

      <MailList
        invite={content.lang[language].mailList.invite}
        btnTxt={content.lang[language].mailList.mailListBtn}
        placeholder={content.lang[language].mailList.placeholder}
      />
    </div>
  );
}
