import React, { useState, useEffect, createContext, useContext } from 'react';
import { addToCart } from '../utils/APIUtils';
import { add } from 'lodash';

export const CartContext = createContext();
export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  //use reduce to calculate the number of tickets
  const getTicketCount = (cartItems) => {
    let ticketCount = 0;
    cartItems.forEach((cartItem) => {
      const typeCount = cartItem.ticketItems.reduce((tickets, ticket) => {
        return tickets + ticket.selectedQuantity;
      }, 0);
      console.log('typeCount is => ', typeCount);

      ticketCount += typeCount;
    });
    return ticketCount;
  };
  const cartCount = getTicketCount(cartItems);

  console.log('cartCount is => ', cartCount);

  console.log('cartItems are => ', cartItems);

  // Handles the add to cart action
  const handleAddToCart = async (
    items,
    performanceId,
    eventId,
    performanceName,
    eventName
  ) => {
    console.log('handleAddToCart called to add item => ', items);

    /* 
      TODO: Add the logic to check if there is already a ticket for the performanceId
      If so, send the cartId to the backend as well
    */
    const addToCartResponse = await addToCart(items);
    console.log('addToCartResponse is => ', addToCartResponse);

    // TODO: Change the logic to receive the response from the backend
    // Check if there is already an entry for the performanceId
    // If not, add a new entry and add the cart data to local storage
    const existingIndex = cartItems.findIndex(
      (item) => item.performanceId === addToCartResponse.performanceId
    );

    setCartItems((prevItems) => {
      // Clone the previous cartItems
      const updatedItems = [...prevItems];

      // If there is no existing entry, add a new one
      if (existingIndex === -1) {
        updatedItems.push(addToCartResponse);
      } else {
        // If there is an existing entry, update its ticketItems
        const existingTickets = updatedItems[existingIndex].ticketItems;

        addToCartResponse.ticketItems.forEach((newItem) => {
          const typeIndex = existingTickets.findIndex(
            (ticket) => ticket.typeId === newItem.typeId
          );

          if (typeIndex === -1) {
            // Add the new item if it doesn't exist
            existingTickets.push(newItem);
          } else {
            // Update the quantity for existing items
            existingTickets[typeIndex].selectedQuantity +=
              newItem.selectedQuantity;
          }
        });
      }

      return updatedItems; // Ensure you're returning the updated array
    });
  };
  const handleRemoveFromCart = (item) => {
    console.log('handleRemoveFromCart called to remove item => ', item);
  };

  return (
    <CartContext.Provider
      value={{ cartItems, handleAddToCart, handleRemoveFromCart, cartCount }}
    >
      {children}
    </CartContext.Provider>
  );
};
