import styles from './EventPreview.module.scss';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getEventDetails } from '../../utils/APIUtils';
import {
  getStartingPrice,
  getStartingDate,
  loadImage,
  getShowingLanguage,
} from '../../utils/CommonUtils';
import EventPerformanceHeader from '../../components/EventPerformanceHeader/EventPerformanceHeader';
import { useLanguage } from '../../context/useLanguage';
import PerformanceCard from '../../components/PerformanceCard/PerformanceCard';
import SponsorCardsContainer from '../../components/SponsorCardsContainer/SponsorCardsContainer';
import AboutEventPerfomance from '../../components/AboutEventPerfomance/AboutEventPerfomance';
import ImagesSection from '../../components/ImagesSection/ImagesSection';
import VideoContainer from '../../components/VideoSection/VideoSection';
import SocialSection from '../../components/SocialSection/SocialSection';
import VIPSection from '../../components/VIPSection/VIPSection';
import { eventTransformData } from './eventTransformData';

export default function EventPreview() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [banner, setBanner] = useState(null);
  const [eventImgs, setEventImgs] = useState([]);

  // Fetch event details
  const {
    data: eventDetails,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['eventDetails', eventId],
    queryFn: async () => getEventDetails(eventId),
  });

  // TODO: ADD ERROR HANDLER FOR FETCHING DATA

  console.log(`eventDetails for event ${eventId} `, eventDetails);
  // Load event images
  useEffect(() => {
    if (!isFetching && eventDetails) {
      console.log('It is not fetching');
      const descriptionImgURLs = [];
      const bannerURL = `${process.env.REACT_APP_IMG_BASE_URL}${eventDetails.mainImages[0]}/public`;
      loadImage(bannerURL, null, setBanner);

      eventDetails.description.images.slice(0, 4).forEach((imgId) => {
        const imgURL = `${process.env.REACT_APP_IMG_BASE_URL}${imgId}/public`;
        loadImage(imgURL, null, (img) => descriptionImgURLs.push(img));
      });

      setEventImgs(descriptionImgURLs);
    }
  }, [isFetching, eventDetails, eventId]);
  if (!isFetching && !eventDetails) {
    navigate('/error');
  }

  const showingLanguage = getShowingLanguage(
    eventDetails?.contentLanguages,
    language
  );

  const {
    title,
    startTime,
    startingPrice,
    sponsors,
    promoterName,
    promoterLink,
    aboutEvent,
    videos,
    socials,
    performances,
  } = !isFetching && eventTransformData(eventDetails);

  return (
    <div className={styles.eventPreview}>
      {isFetching ? (
        <p>Loading...</p> // TODO: Add loading animation while fetching data
      ) : (
        <div className={styles.eventContainer}>
          <EventPerformanceHeader
            banner={banner}
            title={title[showingLanguage]}
            startTime={startTime}
            shareFunction={() => console.log('share')}
            startingPrice={startingPrice}
            sponsors={sponsors}
            promoterName={promoterName}
            promoterLink={promoterLink}
            eventId={eventId}
            language={showingLanguage}
          />
          <AboutEventPerfomance
            content={aboutEvent[showingLanguage]}
            type="event"
          />
          {eventImgs.length > 0 && <ImagesSection images={eventImgs} />}
          {videos.length > 0 && <VideoContainer videoId={videos[0].id} />}
          {socials.length > 0 && (
            <SocialSection socialMediaAccounts={socials} />
          )}
          <VIPSection />
          <section className={styles.performances}>
            <div className={styles.performancesContainer}>
              <div className={styles.performancesCards}>
                <h3>Performances</h3>
                <div className={styles.performancesCardsContainer}>
                  {performances.map((performance) => (
                    <PerformanceCard
                      key={performance.id}
                      performance={performance}
                      imgId={performance.featureImage[0]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>
          {sponsors && sponsors.length > 0 && (
            <section className={styles.sponsors}>
              <SponsorCardsContainer sponsors={sponsors} />
            </section>
          )}
        </div>
      )}
    </div>
  );
}
