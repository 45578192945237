import styles from './ContactInfoSavedModal.module.scss';

export default function ContactInfoSavedModal() {
  return (
    <div className={styles.contactInfoSavedModal}>
      <h2>Contact info saved</h2>
      <p>Your contact information was successfully saved.</p>
    </div>
  );
}
