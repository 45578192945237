export const transformPerfomanceData = (data) => {
  const transformedData = {
    eventTitle: data.event.title,
    performanceTitle: data.details.title,
    // perfomanceId: data.details.id,
    startTime: data.details.startTime,
    startingPrice: data.details.startingPrice,
    // sponsors: data.sponsors,
    promoterName: data.promoter.name,
    promoterLink: data.promoter.customPath || null,
    location: data.location,
    aboutPerformance: data.details.description?.content || null,
    videos: data.details.description?.videos || null,
    socials: data.event.socialMedia,
    otherPerformances: data.event.performances.filter(
      (performance) => performance.id !== data.details.id
    ),
    sponsors: data.event.sponsors,
    eventLink: `/events/${data?.details?.eventId}`,
  };
  return transformedData;
};
