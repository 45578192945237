import styles from './CustomButton.module.scss';

export default function CustomButton({
  clickHandler,
  isPill,
  title,
  colorScheme,
  type,
  variant,
  disabled,
}) {
  // Color scheme can be 'blue', 'white', black - other values need to be added to the styles file
  const cScheme = colorScheme || 'blue'; // If the color scheme is not provided, default to blue
  return (
    <button
      onClick={clickHandler ? clickHandler : () => {}}
      className={`${styles.customButton} ${isPill ? styles.pill : ''} ${
        styles[cScheme]
      } ${variant ? styles[variant] : ''}`}
      type={type ? type : 'button'}
      disabled={disabled}
    >
      {title}
    </button>
  );
}
