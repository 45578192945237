import styles from './CustomRadioButton.module.scss';
import radioChecked from '../../assets/radioCheck.svg';

export default function CustomRadioButton({
  onChangeChecked,
  variant,
  btnValue,
  radioCurrentValue,
  label,
  optionJSX,
  disabled,
}) {
  const checked = radioCurrentValue === btnValue;
  const getClassName = () => {
    return `${styles.customRadioBtn} ${variant ? styles[variant] : ''}`;
  };

  return (
    <span className={getClassName()}>
      <button
        type="button"
        value={btnValue}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onChangeChecked(btnValue);
        }}
        disabled={disabled}
      >
        {checked && <img src={radioChecked} alt="checked" />}
      </button>

      {label ? <span className={styles.label}>{label}</span> : optionJSX}
    </span>
  );
}
