import React, { useState, useRef } from 'react';
import { useAuth } from '../../context/useAuth';
import styles from './AuthenticatedUserHeader.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOutsideClick from '../../utils/useOutsideClick';

export default function AuthenticatedUserHeader() {
  const auth = useAuth();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const modalRef = useRef();
  const togglerRef = useRef();

  /**
   * This function is used to handle the logout event.
   *
   * @return {void}
   */
  const handleLogout = () => {
    navigate('/logout');
  };

  // Use the custom hook to handle outside click of the dropdown
  useOutsideClick(modalRef, (event) => {
    if (
      show &&
      togglerRef.current &&
      !togglerRef.current.contains(event.target)
    )
      setShow(false);
  });

  return (
    <div className={styles.authenticatedUserHeader}>
      <button
        className={styles.toggler}
        onClick={() => setShow(!show)}
        ref={togglerRef}
      >
        {auth.user.profileImageURL ? (
          <img
            src={auth.user.profileImageURL}
            alt="profile pic"
            className={styles.profileImg}
          />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} />
        )}
      </button>

      <div
        className={`${styles.dropdown} ${show ? styles.show : ''}`}
        ref={modalRef}
      >
        <div className={styles.btnsContainer}>
          <Link
            to="/account-settings"
            className={styles.btn}
            onClick={() => setShow(false)}
          >
            <span>My Dashboard</span>
          </Link>
          <button onClick={handleLogout} className={styles.btn}>
            <span>Log out</span>
          </button>
          <Link
            as={Link}
            to="liked-followed"
            className={styles.btn}
            onClick={() => setShow(false)}
          >
            <span>Liked & Followed</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
