import styles from './SponsorCard.module.scss';
import { useState, useEffect } from 'react';
import sponsorIcon from '../../assets/sponsorIcon.svg';
import { loadImage } from '../../utils/CommonUtils';

export default function SponsorCard({ img, title, link }) {
  const [logo, setLogo] = useState(sponsorIcon);

  useEffect(() => {
    loadImage(
      `${process.env.REACT_APP_IMG_BASE_URL}${img}/public`,
      null,
      setLogo
    );
  }, []);
  const classes = link
    ? styles.sponsorCard
    : `${styles.sponsorCard} ${styles.sponsorCardNoLink}`;

  return (
    <a className={classes} href={link}>
      <img src={logo} alt="sponsor logo" />
      <p>{title}</p>
    </a>
  );
}
