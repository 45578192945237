import styles from './AboutEventPerfomance.module.scss';
import SunEditorOutlet from '../SunEditorOutlet/SunEditorOutlet';

export default function AboutEventPerfomance({ content, type }) {
  const title = `About The ${type}`;
  return (
    <section className={styles.aboutEventPerfomance}>
      <div className={styles.aboutEventPerfomanceContainer}>
        <h2>{title}</h2>
        <SunEditorOutlet content={content} styles={styles.aboutEventContent} />
      </div>
    </section>
  );
}
