import styles from './PerformanceCard.module.scss';
import { useLanguage } from '../../context/useLanguage';
import {
  formatStartAndEndTimes,
  formatVenueAddress,
} from '../../utils/CommonUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationDot,
  faCalendarDays,
  faTicket,
} from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../CustomButton/CustomButton';
import ViewTicketsBtnContent from '../ViewTicketsBtnContent/ViewTicketsBtnContent';
import { useNavigate } from 'react-router-dom';

export default function PerformanceCard({ performance, imgId }) {
  console.log('performance => ', performance);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { title, id, location, startTime, endTime, startingPrice } =
    performance;
  const handleBuyClick = () => {
    console.log('handleBuyClick');
    navigate(`/performance/${id}`);
  };

  const formattedDate = formatStartAndEndTimes(startTime, endTime);

  const imgURL = `${process.env.REACT_APP_IMG_BASE_URL}${imgId}/public`;

  return (
    <div className={styles.performanceCard}>
      <div className={styles.blueStripe}></div>
      <div className={styles.performanceCardContainer}>
        <div className={styles.group}>
          <img src={imgURL} alt={`${title[language]} performance thumbnail`} />
          <div className={styles.dataContainer}>
            <h3>{title[language]}</h3>
            <div className={styles.date}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faCalendarDays} />
              </span>
              <span>{formattedDate.start}</span>-
              <span>{formattedDate.end}</span>
            </div>
            <div className={styles.location}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faLocationDot} />
                <span>{formatVenueAddress(location)}</span>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <CustomButton
            clickHandler={handleBuyClick}
            variant={'eventBuyTickets'}
            title={
              <ViewTicketsBtnContent
                startingPrice={startingPrice}
                icon={faTicket}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
