import { getStartingDate, getStartingPrice } from '../../utils/CommonUtils';
export const eventTransformData = (data) => {
  console.log('eventTransformData: ', data);
  const transformedData = {
    title: data.title,
    startTime: getStartingDate(data.performances),
    startingPrice: getStartingPrice(data.performances),
    sponsors: data.sponsors,
    promoterName: data.promoter.name,
    promoterLink: data.promoter.customPath || null,
    aboutEvent: data.description?.content || null,
    videos: data.description?.videos || null,
    socials: data.socialMediaAccounts,
    performances: data.performances,
  };

  return transformedData;
};
