import styles from './ImageThumbnail.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
export default function ImageThumbnail({ profilePic, onEditClick }) {
  return (
    <button
      onClick={onEditClick}
      className={styles.imageThumbnail}
      type="button"
    >
      {profilePic ? (
        <img src={profilePic} alt="profile" />
      ) : (
        <FontAwesomeIcon icon={faUser} />
      )}
    </button>
  );
}
