import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styles from './NavItem.module.scss';

export default function NavItem({ linkTo, label }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleRouteSelection = (e) => {
    e.preventDefault();
    navigate(`/${linkTo}`);
  };

  return (
    <div className={styles.navItem}>
      <button
        onClick={handleRouteSelection}
        className={`${styles.link} ${
          pathname === `/${linkTo}` ? styles.active : ''
        }`}
      >
        <span>{label}</span>
      </button>
    </div>
  );
}
