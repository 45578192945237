import React from 'react';
import { navigateToPromoter } from '../../utils/NavigationUtils';
import styles from './About.module.scss';
import testemonialImg from '../../assets/testimonial.jpeg';
import CustomButton from '../../components/CustomButton/CustomButton';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

/**
 * Renders a section about the company Ticket Window Inc.
 *
 * @return {JSX.Element} The JSX element representing the About section.
 */

const About = () => {
  return (
    <div className={styles.about}>
      <div className={styles.aboutContainer}>
        {/* <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: decodeContent('about.pageTitle') }}
        ></div> */}
        <h3 className={styles.aboutTitle}>About Us</h3>
        <h5 className={styles.subHeading}>Who is Ticketwindow Inc?</h5>
        <p className={styles.text}>
          Ticket Window was founded in 2004 and operates its software support in
          London Ontario Canada as an International Company providing cutting
          edge box office ticketing software and event support services to a
          variety of soft seat performing arts theaters, indoor concerts,
          festivals and big ticket events in Canada and the US.
        </p>
        <h5 className={styles.subHeading}>A New Era in Streaming</h5>
        <p className={styles.text}>
          Virtual event production is still in its infant stage but advancing
          quickly. Most streaming platforms provide basically no functionality
          when viewing a stream but does allows fans to enjoy a concert in the
          comfort of their home. The [UX] yoU eXperience module takes a few
          steps forward using the innovative technology with creative thinking
          to provide an interactive stream controller for fans to choose when
          they want any camera to view during a performance on their own PC, IOS
          or Android Device.
        </p>
        <p className={styles.text}>
          For Creators, this technology opens many opportunities to help with
          audience/fan retention, and an ideal way to monetize an event and
          increase the bottom line long after a performance is over.
        </p>

        <div className={styles.cta}>
          <div className={styles.imgContainer}>
            <img src={testemonialImg} alt="A person with positive feedback" />
          </div>
          <div className={styles.actionContainer}>
            <h3>
              “This is a space for a short testimonial of how TicketwindowUX
              help folks to start creating their own events”
            </h3>
            <div className={styles.btnContainer}>
              <CustomButton
                clickHandler={navigateToPromoter}
                title="Get Started"
                variant="aboutUs"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
