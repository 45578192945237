import { useEffect } from 'react';
import { useAuth } from '../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import * as storageUtils from '../../utils/LocalStorageUtils';

export default function Logout() {
  console.log('Logout called');
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('useEffect called in Logout');

    if (auth.user) {
      console.log('auth.user: ', auth.user);
      const handleLogout = async () => {
        // Perform any asynchronous logout logic if needed
        await auth.logout();

        // Remove the expiration date from local storage
        storageUtils.removeItem('expirationDate');
      };

      handleLogout();
    }
  }, [auth, navigate]);

  useEffect(() => {
    if (!auth.user) {
      navigate('/login');
    }
  }, [auth.user]);
  return null;
}
