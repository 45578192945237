import React, { useState, useEffect, useRef } from 'react';
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import styles from './GooglePlacesInput.module.scss';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GooglePlacesInput = ({
  address,
  label,
  ariaLabel,
  onSelectValue,
  addressType,
  setWasEdited,
}) => {
  const onPlaceSelect = (place) => {
    onSelectValue(place, addressType);
  };

  const PlaceAutocomplete = ({ onPlaceSelect }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
      if (!places || !inputRef.current) return;
      // Initialize Google Places Autocomplete
      const options = {
        fields: ['address_components'],
      };
      setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);

    useEffect(() => {
      if (!placeAutocomplete) return;

      // Event listener handler for place selection
      placeAutocomplete.addListener('place_changed', () => {
        const place = placeAutocomplete.getPlace();
        onPlaceSelect(place);
        onSelectValue(place, addressType);
        setIsEditing(false);
        setWasEdited(true);
      });
    }, [onPlaceSelect, placeAutocomplete]);

    const getInputClasses = () => {
      if (!isEditing) {
        return `${styles.hidden}`;
      } else {
        return '';
      }
    };

    return (
      <div className={styles.googlePlacesInput}>
        <label className={styles.label}>{label}</label>
        <input ref={inputRef} className={getInputClasses()} />
        {!isEditing && (
          <button
            name="addressLine1"
            aria-label={ariaLabel}
            type="text"
            onClick={() => setIsEditing(true)}
          >
            {address.addressLine1}
          </button>
        )}
      </div>
    );
  };

  return (
    <APIProvider apiKey={API_KEY}>
      <PlaceAutocomplete onPlaceSelect={onPlaceSelect} />
    </APIProvider>
  );
};

export default GooglePlacesInput;
