import DOMPurify from 'dompurify';

export default function EventDescription({ content, styles }) {
  const sanitizedContent = DOMPurify.sanitize(content);
  return (
    <div
      className={styles}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  );
}
