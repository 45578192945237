import axios from 'axios';
export const getUserLocation = async () => {
  if (!navigator.geolocation) {
    throw new Error('Geolocation is not supported by this browser.');
  }

  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        resolve,
        (error) => reject(error) // Pass the error object directly to the reject function
      );
    });

    return {
      lat: position.coords.latitude,
      lon: position.coords.longitude,
    };
  } catch (error) {
    // Handle different error codes
    switch (error.code) {
      case error.PERMISSION_DENIED:
        throw new Error('User denied the request for Geolocation.');
      case error.POSITION_UNAVAILABLE:
        throw new Error('Location information is unavailable.');
      case error.TIMEOUT:
        throw new Error('The request to get user location timed out.');
      default:
        throw new Error('An unknown error occurred.');
    }
  }
};

export const getRoughLocation = async () => {
  try {
    const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
    const data = response.data;
    console.log('Fetched IP location data:', data);

    console.log(
      `City: ${data.city}, Region: ${data.region}, Country: ${data.country}`
    );

    return {
      city: data.city,
      countryCode: data.country_code,
      geometry: { lat: data.latitude, lon: data.longitude },
    };
  } catch (error) {
    console.error('Error fetching IP location:', error);
  }
};
