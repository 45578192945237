import styles from './ImageCropper.module.scss';
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './imageHelpers';
import CustomButton from '../CustomButton/CustomButton';
// import 'cropperjs/dist/cropper.css';

export default function ImageCropper({ image, onCropDone, onCropCancel }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  //   const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCrop = useCallback(async () => {
    try {
      const croppedImageUrl = await getCroppedImg(image, croppedAreaPixels);
      onCropDone(croppedImageUrl);
    } catch (error) {
      console.error(error);
    }
  }, [image, croppedAreaPixels, onCropDone]);

  return (
    <div className={styles.imageCropper}>
      <div className={styles.imageCropperContainer}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className={styles.inputContainer}>
        <input
          className={styles.slider}
          type="range"
          value={zoom}
          min={1}
          max={5}
          step={0.1}
          onChange={(e) => setZoom(e.target.value)}
        />
        <CustomButton
          clickHandler={onCrop}
          type="button"
          title="Crop Image"
          variant="crop"
        />
      </div>
    </div>
  );
}
