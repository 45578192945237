import styles from './TicketSection.module.scss';
import { formatNumberToCurrency } from '../../utils/CommonUtils';

export default function TicketSection({
  allowedTickets,
  section,
  showingLanguage,
  ticketsSelected,
  onValueChange,
  numberOfTicketsSelected,
  performanceId,
  onAddToCart,
}) {
  const capacity = section.capacity;
  const sectionName = section.name[showingLanguage];
  const types = section.types;
  console.log('section is => ', section);
  console.log('types are => ', types);
  console.log('ticketsSelected in TicketSectionare => ', ticketsSelected);

  return (
    <section className={styles.ticketSection}>
      <div className={styles.ticketSectionContainer}>
        <div className={styles.titleLine}>
          <span className={styles.sectionName}>
            {section.name[showingLanguage]}
          </span>
          <span>Quantity</span>
        </div>
        {types.map((type) => (
          <div className={styles.ticketLine} key={type.id}>
            <p className={styles.ticketName}>
              <span>{type.name[showingLanguage]}</span>
              <span>{formatNumberToCurrency(type.price)}</span>
            </p>
            <span className={styles.ticketQuantity}>
              {ticketsSelected && (
                <input
                  type="number"
                  max={allowedTickets}
                  //   setValue={handleValueChange}
                  value={ticketsSelected[type.id]?.selectedQuantity || 0}
                  onChange={onValueChange}
                  name={type.id}
                />
              )}
            </span>
          </div>
        ))}
      </div>
    </section>
  );
}
