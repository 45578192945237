import styles from './SocialSection.module.scss';
import { getSocialMediaLink } from '../../utils/CommonUtils';
import SocialLink from '../SocialLink/SocialLink';
export default function SocialSection({ socialMediaAccounts }) {
  return (
    <section className={styles.social}>
      <div className={styles.socialContainer}>
        <h3>Follow Us</h3>
        <div className={styles.socialLinks}>
          {socialMediaAccounts.map((social) => {
            const { link, icon } = getSocialMediaLink(social);
            return (
              <SocialLink
                link={link}
                name={social.platform}
                icon={icon}
                key={social.accountId}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
