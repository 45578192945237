import styles from './ImageUploader.module.scss';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageCropper from './ImageCropper';
import CustomButton from '../CustomButton/CustomButton';
import { useModal } from '../../context/useModal';

export default function ImageUploader({ onImageChange, onCancelUpload }) {
  const [tempImage, setTempImage] = useState(null);
  const { openModal, closeModal, showModal } = useModal();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const imageUrl = URL.createObjectURL(file);
    console.log('file => ', file);
    console.log('imageUrl => ', imageUrl);

    setTempImage(imageUrl);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const onCropDone = (img) => {
    onImageChange(img);
    closeModal();
  };

  const showImageCropper = () => {
    openModal(<ImageCropper image={tempImage} onCropDone={onCropDone} />);
  };

  useEffect(() => {
    if (tempImage) {
      showImageCropper(
        <ImageCropper image={tempImage} onCropDone={onImageChange} />
      );
    }
  }, [tempImage]);

  useEffect(() => {
    if (!showModal && tempImage) {
      setTempImage(null);
    }
  }, [showModal]);

  return (
    <div className={styles.imageUploader}>
      {!tempImage && (
        <div className={styles.imageUploader}>
          <div {...getRootProps()} className={styles.imageUploaderContainer}>
            <input {...getInputProps()} />
            <p>Drag & drop, or click to select an image</p>
          </div>
          <div className={styles.btnContainer}>
            <CustomButton
              title="Cancel"
              clickHandler={() => {
                onCancelUpload(false);
              }}
              type="button"
              variant="imgUploaderCancel"
            />
          </div>
        </div>
      )}
    </div>
  );
}
