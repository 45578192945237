import FormInput from '../../../components/FormInput/FormInput';

export default function BasicInfoSection({
  styles,
  userDetails,
  handleUserDetailsChange,
  errors,
  setErrors,
}) {
  return (
    <section className={styles.basicInfo}>
      <h3>Basic information</h3>
      <div className={styles.nameContainer}>
        <FormInput
          ariaLabel="first name"
          label="First Name"
          placeholder=""
          value={userDetails.firstName}
          name="firstName"
          labelColor={'semiDark'}
          isRequired={true}
          inputChangeHandler={handleUserDetailsChange}
          onClearError={setErrors}
          error={errors.firstName || errors.updating || null}
        />
        <FormInput
          ariaLabel="last name"
          label="Last Name"
          placeholder=""
          value={userDetails.lastName}
          name="lastName"
          labelColor={'semiDark'}
          isRequired={true}
          inputChangeHandler={handleUserDetailsChange}
          onClearError={setErrors}
          error={errors.lastName || null}
        />
      </div>
      <div className={styles.phoneContainer}>
        <FormInput
          ariaLabel="phone"
          label="Phone"
          placeholder=""
          value={userDetails.phoneNumber}
          name="phoneNumber"
          labelColor={'semiDark'}
          inputChangeHandler={handleUserDetailsChange}
          onClearError={setErrors}
          error={errors.phoneNumber || null}
        />
      </div>
    </section>
  );
}
