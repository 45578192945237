import styles from './EventsFilter.module.scss';
import CustomRadioButton from '../CustomRadioButton/CustomRadioButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CitySearchInput from '../LocationSearch/CitySearchInput';
import ZipCodeSearchInput from '../LocationSearch/ZipCodeSearchInput';
import {
  getUserLocation,
  getRoughLocation,
} from '../../utils/LocalizationUtils';
import { useState } from 'react';

const CheckboxGroup = ({ selectedTypes, onChangeChecked, value }) => {
  const checked = selectedTypes.includes(value);

  return (
    <div className={styles.checkboxGroup}>
      <CustomCheckbox
        checked={checked}
        onChangeChecked={() => onChangeChecked(value)}
        variant="filterCheckbox"
      />
      <span>{value}</span>
    </div>
  );
};

export default function EventsFilter({
  formats,
  selectedFormat,
  onFormatChange,
  categories,
  selectedCategories,
  onCategoryChange,
  // types,
  // selectedTypes,
  // onTypesChange,
  datesOptions,
  selectedDateOpt,
  onDateOptChange,
  onLocationSet,
  languageOptions,
  selectedLanguageOpt,
  onLanguageOptChange,
}) {
  const [locationSearchOpt, setLocationSearchOpt] = useState('city');
  const [canUseMyLocation, setCanUseMyLocation] = useState(true);

  const myLocationSelectionHandler = async () => {
    setLocationSearchOpt('myLocation');
    try {
      const userLocation = await getUserLocation();
      console.log('User location coordinates => ', userLocation);

      onLocationSet(userLocation);
    } catch (error) {
      console.error('Error getting user location:', error);
      setCanUseMyLocation(false);
    }
  };
  return (
    <div className={styles.eventsFilter}>
      <section>
        <h3>Format</h3>
        <div className={styles.filterOpts}>
          {formats.map((format) => (
            <CustomRadioButton
              key={format}
              label={format}
              btnValue={format}
              radioCurrentValue={selectedFormat}
              onChangeChecked={onFormatChange}
              variant="eventFilter"
            />
          ))}
        </div>
        <div className={styles.divider}></div>
      </section>
      <section>
        <h3>Category</h3>
        <div className={styles.filterOpts}>
          {categories.map((category) => (
            <CheckboxGroup
              value={category}
              onChangeChecked={onCategoryChange}
              key={category}
              selectedTypes={selectedCategories}
            />
          ))}
        </div>
        <div className={styles.divider}></div>
      </section>
      {/* <section>
        <h3>Types</h3>
        <div className={`${styles.filterOpts} ${styles.types}`}>
          {types.map((type) => (
            <CheckboxGroup
              value={type}
              onChangeChecked={onTypesChange}
              key={type}
              selectedTypes={selectedTypes}
            />
          ))}
        </div>
        <div className={styles.divider}></div>
      </section> */}
      <section>
        <h3>Date</h3>
        <div className={styles.filterOpts}>
          {datesOptions.map((type) => (
            <CustomRadioButton
              key={type}
              label={type}
              btnValue={type}
              radioCurrentValue={selectedDateOpt}
              onChangeChecked={onDateOptChange}
              variant="eventFilter"
            />
          ))}
        </div>
        <div className={styles.divider}></div>
      </section>
      <section>
        <h3>Search Range</h3>
        <div className={styles.filterOpts}>
          <div className={styles.locationOpts}>
            <CustomRadioButton
              variant={'eventFilter'}
              optionJSX={<CitySearchInput onSelectCity={onLocationSet} />}
              onChangeChecked={setLocationSearchOpt}
              btnValue="city"
              radioCurrentValue={locationSearchOpt}
            />
            <CustomRadioButton
              label="My Location"
              variant="eventFilter"
              radioCurrentValue={locationSearchOpt}
              onChangeChecked={myLocationSelectionHandler}
              btnValue="myLocation"
              disabled={!canUseMyLocation}
            />
          </div>
          <div className={styles.locationOpts}></div>
          <div className={styles.locationOpts}></div>
        </div>
        <div className={styles.divider}></div>
      </section>
    </div>
  );
}
