import NotAuthenticatedUserHeader from '../NotAuthenticatedUserHeader/NotAuthenticatedUserHeader';
import AuthenticatedUserHeader from '../AuthenticatedUserHeader/AuthenticatedUserHeader';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import { useAuth } from '../../context/useAuth';
import { useState, useEffect, useRef } from 'react';
import styles from './AuthNLngContainer.module.scss';
import * as storageUtils from '../../utils/LocalStorageUtils';
import { checkCookieExpiration } from '../../utils/APIUtils';
import { useNavigate } from 'react-router-dom';

export default function AuthNLngContainer() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(
    auth.user ? true : false
  );
  const intervalRef = useRef(null);

  // When the user changes, check if the user is logged in..
  useEffect(() => {
    console.log(
      'User changed on AuthNLngContainer, and user is => ',
      auth.user
    );

    // let intervalId;
    if (auth.user) {
      console.log(
        'checking expiration on AuthNLngContainer triggered. User logged in'
      );
      // Check if there is a previous interval in place, and remove it
      if (intervalRef.current) {
        console.log('clearing previous interval');
        clearInterval(intervalRef.current);
      }

      const expirationDate = storageUtils.getItem('expirationDate');

      if (expirationDate && expirationDate > Date.now()) {
        console.log('Expiration date found and is ahead of now');
        // Check the expiration date of the token each 5 minutes
        // checkTokenExpiration automatically calls the renewSession function if necessary
        intervalRef.current = setInterval(() => {
          console.log('Interval triggered for AuthNLngContainer');
          if (!auth.user) {
            // Clear the interval if the user logs out
            clearInterval(intervalRef.current);
            // Set isAuthenticated to false
            setIsAuthenticated(false);
            // Navigate to the login page
            console.log(
              'User logged out - navigating to login page on AuthNLngContainer'
            );

            navigate('/login');
          } else {
            console.log('Checking cookie expiration on AuthNLngContainer');

            checkCookieExpiration().then((response) => {
              if (!response) {
                // Clear the interval if the user logs out
                clearInterval(intervalRef.current);
                navigate('/logout');
              }
            });
          }
        }, 5 * 60 * 1000);
      }
      setIsAuthenticated(true);
      // Cancel the interval if the expiration date is reached
      if (Date.now() > expirationDate && intervalRef.current) {
        clearInterval(intervalRef.current);
        setIsAuthenticated(false);
        navigate('/logout');
      }
    } else {
      // In case the user logs out, clear the interval
      if (intervalRef.current) clearInterval(intervalRef.current);
      setIsAuthenticated(false);
    }
    return () => {
      console.log('clearing interval on AuthNLngContainer');
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [auth.user]);
  return (
    <div className={styles.authNLngContainer}>
      {isAuthenticated ? (
        <AuthenticatedUserHeader />
      ) : (
        <NotAuthenticatedUserHeader />
      )}
      <LanguagePicker />
    </div>
  );
}
