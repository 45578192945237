import styles from './ImagesSection.module.scss';

export default function ImagesSection({ images }) {
  return (
    <section className={styles.imagesContainer}>
      <div className={styles.images}>
        {images.map((img, index) => (
          <img src={img} alt={`event ${index + 1}`} key={index} />
        ))}
      </div>
    </section>
  );
}
