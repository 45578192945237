import styles from './ImagePicker.module.scss';
import { useCallback, useState, useEffect } from 'react';
import ImageUploader from './ImageUploader';
import ImageThumbnail from './ImageThumbnail';
export default function ImagePicker({ profileImage, onImageChange }) {
  const [showDropzone, setShowDropzone] = useState(false);

  console.log('profileImage => ', profileImage);

  const setNewImage = useCallback((newImage) => {
    onImageChange(newImage);
    setShowDropzone(false);
  }, []);

  return (
    <div className={styles.imagePicker}>
      {showDropzone ? (
        <ImageUploader
          image={profileImage}
          onImageChange={setNewImage}
          onCancelUpload={setShowDropzone}
        />
      ) : (
        <ImageThumbnail
          profilePic={profileImage}
          onEditClick={() => setShowDropzone(!showDropzone)}
        />
      )}
    </div>
  );
}
