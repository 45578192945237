import React, { useState, useEffect, useContext, createContext } from 'react';
import { getUserDetails, logoutUser } from '../utils/APIUtils';

// Empty address
const emptyAddress = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  province: '',
  postalCode: '',
  country: '',
};

/**
 * The auth context is a React context that holds the auth object. It is created
 * using the createContext function from the React library.
 *
 * @type {React.Context<Object>}
 */
const authContext = createContext();

/**
 * ProvideAuth is a React component that wraps your app and makes the auth object
 * available to any child component that calls useAuth().
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} - The rendered component.
 */

/**
 * ProvideAuth is a React component that wraps your app and makes the auth object
 * available to any child component that calls useAuth().
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} - The rendered component.
 */

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * Hook for child components to get the auth object and
 * re-render when it changes.
 *
 * @return {Object} The auth object.
 */
export const useAuth = () => {
  return useContext(authContext);
};

/**
 * Hook that provides the auth object and handles state.
 *
 * @return {Object} The auth object.
 */
function useProvideAuth() {
  const [user, setUser] = useState(null);

  /**
   * Effect hook that subscribes to user on mount and retrieves user data from local storage.
   * Causing any component that utilizes this hook to re-render with the latest auth object.
   *
   * @return {void}
   */

  useEffect(() => {
    getUserDetails()
      .then((res) => {
        console.log('user details response: ', res);
        if (res) {
          formatAndSetUser(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setUser(null);
      });
  }, []);

  const formatAndSetUser = (user) => {
    if (user.billingAddress === null) {
      user.billingAddress = structuredClone(emptyAddress);
    }
    if (user.shippingAddress === null) {
      user.shippingAddress = structuredClone(emptyAddress);
    }
    setUser(user);
  };

  const logout = async () => {
    setUser(null);
    console.log('Logout called in useAuth');
    const logoutResponse = await logoutUser();
    console.log('logout response: ', logoutResponse);
  };

  /**
   * Returns the user object, a function to set the user object, and a logout function.
   *
   * @return {Object} An object containing the user object, a function to set the user object, and a logout function.
   * @property {Object} user - The user object.
   * @property {function} setUser - A function to set the user object.
   * @property {function} logout - A function to log the user out.
   */
  return { user, logout, formatAndSetUser };
}
