import React, { useRef } from 'react';
import styles from './Modal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useModal } from '../../context/useModal';
import useOutsideClick from '../../utils/useOutsideClick';

export default function Modal() {
  const { modalContent, closeModal, showModal, variant } = useModal();
  const modalRef = useRef(null);
  // Use the custom hook to handle outside click
  useOutsideClick(modalRef, () => {
    if (showModal) closeModal(); // Only set to false if it's currently true
  });

  const getModalClasses = () => {
    let modalClasses = styles.modal;

    if (variant) {
      console.log('variant: ', variant);

      modalClasses = `${modalClasses} ${styles[variant]}`;
    }
    console.log('modalClasses: ', modalClasses);

    return modalClasses;
  };

  return (
    <div
      className={`${styles.modalBackground} ${showModal ? '' : styles.hide}`}
    >
      <div className={getModalClasses()} ref={modalRef}>
        <div className={styles.btnContainer}>
          <button className={styles.closeBtn} onClick={closeModal}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </div>
        <div className={styles.modalBody}>{modalContent}</div>
      </div>
    </div>
  );
}
