import styles from './ViewTicketsBtnContent.module.scss';
import cartIcon from '../../assets/cart.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ViewTicketsBtnContent({
  startingPrice,
  icon,
  variant,
}) {
  const compClass = `${styles.btnTitleContainer} ${
    variant ? styles[variant] : ''
  }`;
  return (
    <div className={compClass}>
      <div className={styles.textContainer}>
        <p>Buy Tickets</p>
        {startingPrice && <span>Start from {startingPrice}</span>}
      </div>
      <div className={styles.imgContainer}>
        {icon ? (
          <FontAwesomeIcon icon={icon} />
        ) : (
          <img src={cartIcon} alt="cart icon" />
        )}
      </div>
    </div>
  );
}
