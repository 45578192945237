import React from 'react';
import ReactPlayer from 'react-player';
import styles from './URLVideoPlayer.module.scss';

export default function URLVideoPlayer({ videoId, width, height, isPlaying }) {
  const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;

  return (
    <div className={styles.urlVideoPlayer}>
      {videoUrl ? (
        <div className={styles.videoWrapper}>
          <ReactPlayer
            url={videoUrl}
            controls
            width={width || '100%'}
            height={height || 'auto'}
            playing={isPlaying || true}
            muted
          />
        </div>
      ) : (
        <p>No video available</p>
      )}
    </div>
  );
}
