import GooglePlacesInput from '../GooglePlacesInput/GooglePlacesInput';
import FormInput from '../FormInput/FormInput';
import SelectInput from '../SelectInput/SelectInput';
import styles from './AddressFormSection.module.scss';
import { COUNTRY_ENUM, PROVINCE_ENUM } from '../../constants/index';

export default function AddressFormSection({
  sectionTitle,
  address,
  onAddressChange,
  errors,
  setErrors,
  setAutocompleteValue,
  addressType,
  setWasEdited,
}) {
  const countryOptions = Object.keys(COUNTRY_ENUM).map((key) => ({
    key: key,
    value: COUNTRY_ENUM[key],
  }));

  const provinceOptions = Object.keys(PROVINCE_ENUM).map((key) => ({
    key: key,
    value: PROVINCE_ENUM[key],
  }));

  return (
    <section className={styles.address}>
      <h3>{sectionTitle}</h3>
      <GooglePlacesInput
        address={address}
        label="Address"
        ariaLabel="Billing Address line 1"
        onSelectValue={setAutocompleteValue}
        addressType={addressType}
        setWasEdited={setWasEdited}
      />
      <FormInput
        ariaLabel="Billing Address line 2"
        label="Address 2"
        placeholder=""
        value={address.addressLine2 ? address.addressLine2 : ''}
        name="addressLine2"
        labelColor={'semiDark'}
        inputChangeHandler={onAddressChange}
        onClearError={setErrors}
        error={errors.billingAddress || null}
      />
      <div className={styles.cityCountryContainer}>
        <FormInput
          ariaLabel="City"
          label="City"
          placeholder=""
          value={address.city ? address.city : ''}
          name="city"
          labelColor={'semiDark'}
          inputChangeHandler={onAddressChange}
          onClearError={setErrors}
          error={errors.billingAddress || null}
        />

        <SelectInput
          label="Country"
          options={countryOptions}
          onChange={onAddressChange}
          value={address.country}
          name="country"
        />
      </div>
      <div className={styles.zipProvinceContainer}>
        <FormInput
          ariaLabel="Postal Code"
          label="Zip/Postal Code"
          placeholder=""
          value={address.postalCode ? address.postalCode : ''}
          name="postalCode"
          labelColor={'semiDark'}
          inputChangeHandler={onAddressChange}
          onClearError={setErrors}
          error={errors.billingAddress || null}
        />
        <SelectInput
          label="Province"
          options={provinceOptions}
          onChange={onAddressChange}
          value={address.province}
          name="province"
        />
      </div>
    </section>
  );
}
