import styles from './VideoSection.module.scss';
import URLVideoPlayer from '../URLVideoPlayer/URLVideoPlayer';
export default function VideoSection({ videoId }) {
  return (
    <section className={styles.videoSection}>
      <div className={styles.videoContainer}>
        <URLVideoPlayer videoId={videoId} isPlaying={false} width="auto" />
      </div>
    </section>
  );
}
