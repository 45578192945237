import { useEffect, useState } from 'react';
import styles from './EventListCard.module.scss';
import CustomButton from '../CustomButton/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ViewTicketsBtnContent from '../ViewTicketsBtnContent/ViewTicketsBtnContent';
import { getStartingPrice, formatDate } from '../../utils/CommonUtils';
import { useLanguage } from '../../context/useLanguage';
import { loadImage } from '../../utils/CommonUtils';

// Helper function to get the starting price of an event
// When it was develped the structure of the event hasn't beed finished yet

export default function EventListCard({ event }) {
  const [showMore, setShowMore] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const {
    title,
    summary,
    featureImage,
    performances,
    shareCount,
    minStartTime,
  } = event;

  const startingPrice = getStartingPrice(performances);
  const startDate = formatDate(minStartTime);

  useEffect(() => {
    const imgURL = `${process.env.REACT_APP_IMG_BASE_URL}${featureImage[0]}/public`;
    loadImage(imgURL, null, setThumbnail);
  }, []);

  const navigate = useNavigate();
  const { language } = useLanguage();
  const handleViewTicketsClick = async (eventId) => {
    console.log('handleViewTicketsClick called for eventId => ', eventId);
    navigate(`/events/${eventId}`);
  };

  return (
    <div className={styles.eventListCard}>
      <div className={styles.cardContainer}>
        <div className={styles.alwaysVisible}>
          <div className={styles.titleContainer}>
            <img src={thumbnail} alt="event thumbnail" />
            <div className={styles.textContainer}>
              <h3>{title[language]}</h3>
              <h4>{startDate}</h4>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <CustomButton
              title={
                <ViewTicketsBtnContent
                  startingPrice={startingPrice}
                  variant={'eventViewTickets'}
                />
              }
              isPill={true}
              variant={'eventViewTickets'}
              clickHandler={() => {
                handleViewTicketsClick(event.id);
              }}
            />
            <span>{shareCount} People shared this event</span>
          </div>
        </div>
        {showMore && <p className={styles.description}>{summary[language]}</p>}
        <button
          className={styles.toogleBtn}
          onClick={() => setShowMore(!showMore)}
        >
          <FontAwesomeIcon icon={showMore ? faChevronUp : faChevronDown} />
        </button>
      </div>
    </div>
  );
}
