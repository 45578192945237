import React, { useEffect, useState } from 'react';
import styles from './CloseAccount.module.scss';
import FormInput from '../../../components/FormInput/FormInput';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { useModal } from '../../../context/useModal';
import { useAuth } from '../../../context/useAuth';
import { logoutUser } from '../../../utils/APIUtils';
import CloseAccountPopupContent from '../../../components/CloseAccountPopupContent/CloseAccountPopupContent';
import { useNavigate } from 'react-router-dom';
import {
  checkUserCredentials,
  closePatronAccount,
} from '../../../utils/APIUtils';
import CustomRadioButton from '../../../components/CustomRadioButton/CustomRadioButton';
import { removeItem } from '../../../utils/LocalStorageUtils';

/**
 * An enumeration representing the reasons for closing the account.
 * If the reasons are changed, please update the REASONS enum in this file.
 * @enum {string}
 * @readonly
 */
const REASONS = Object.freeze({
  1: 'No longer attending events',
  2: '“I don’t attend events anymore“',
  3: 'Not enough relevant events',
  4: '"I can’t find events that interest me"',
  5: 'Too expensive',
  6: '"The service or ticket prices are too high"',
  7: 'Technical or usability issues',
  8: '"I experienced problems with the website or app"',
  9: 'Concerns about privacy or security',
  10: '"I’m worried about my data privacy or security"',
  11: 'None of the above',
});

export default function CloseAccount() {
  const [password, setPassword] = useState('');
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [errors, setErrors] = useState({});
  const [reason, setReason] = useState('11');
  const [credentialsValidated, setCredentialsValidated] = useState(false);
  const { openModal } = useModal();
  const auth = useAuth();
  const navigate = useNavigate();

  const resetComponents = () => {
    // setConfirmCancel(false);
    setCredentialsValidated(false);
    setReason('');
    setPassword('');
    setErrors({});
  };

  const discardCancel = () => {
    console.log('discardCancel');
    resetComponents();
  };

  /**
   * Handles the form submission event.
   * Makes an API call to confirm the user's credentials.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleValidateCredentials = async (e) => {
    e.preventDefault();
    let credentials;
    if (auth.user.authMethod === 'email/password') {
      credentials = { email: auth.user.email, password: password };
    }
    if (auth.user.authMethod === 'GoogleOAuth') {
      credentials = { emailConfirmation: emailConfirmation };
    }
    // API call for confirm credentials
    const credentialsValidated = await checkUserCredentials(credentials);
    // Add error handling
    if (!credentialsValidated.success) {
      setErrors((preErrors) => {
        return {
          ...preErrors,
          credentials: credentialsValidated.message,
        };
      });
    } else {
      // If the credentials are validated, set credentialsValidated to true to show the modal
      setCredentialsValidated(true);
    }
  };

  const handleCancelAccount = async () => {
    const dateOfCancellation = new Date();
    const ISODate = dateOfCancellation.toISOString();
    // Add API call for canceling account
    const response = await closePatronAccount({
      email: auth.user.email,
      reason: REASONS[reason],
      deactivatedAt: ISODate,
    });
    console.log('response in handleCancelAccount', response);

    if (response.status === 200) {
      resetComponents();
      auth.logout();
      removeItem('email');
      logoutUser();
      navigate('/');
    } else {
      setErrors((preErrors) => {
        return {
          ...preErrors,
          closeAccount: response.message,
        };
      });
    }
  };

  useEffect(() => {
    if (credentialsValidated) {
      // Show modal
      openModal(
        <CloseAccountPopupContent
          onConfirmCancel={handleCancelAccount}
          onDiscardCancel={discardCancel}
        />,
        'whiteNoBtn'
      );
    }
  }, [credentialsValidated]);

  return (
    <div className={styles.closeAccount}>
      <h2>Close your account</h2>
      <p className={styles.desc}>
        Please take a moment to let us know why you are leaving.
      </p>
      <form onSubmit={handleValidateCredentials}>
        <div className={styles.radioGroup}>
          {Object.keys(REASONS)
            .filter((key) => key !== '12')
            .map((key) => {
              return (
                <CustomRadioButton
                  key={key}
                  name="reason"
                  label={REASONS[key]}
                  btnValue={key}
                  radioCurrentValue={reason}
                  onChangeChecked={setReason}
                />
              );
            })}
        </div>
        <p className={styles.msg}>
          Please enter your password to confirm that yoou wish to close your
          account
        </p>
        <div className={styles.formGroup}>
          {auth.user.authMethod === 'email/password' && (
            <FormInput
              label="Password"
              type="password"
              value={password}
              onInputChange={setPassword}
              isRequired={true}
              labelColor="semiDark"
              error={errors.credentials || errors.closeAccount}
              onClearError={() => setErrors({})}
              minLength={8}
            />
          )}
          {auth.user.authMethod === 'GoogleOAuth' && (
            <FormInput
              label="Email"
              type="email"
              value={emailConfirmation}
              onInputChange={setEmailConfirmation}
              isRequired={true}
              labelColor="semiDark"
              error={errors.credentials || errors.closeAccount}
              onClearError={() => setErrors({})}
            />
          )}
          <div className={styles.btnsContainer}>
            <CustomButton
              type="submit"
              title="Close Account"
              colorScheme="outlineWhite"
            />
          </div>
        </div>
      </form>
      <div className={styles.divider}></div>
    </div>
  );
}
