import styles from './PremiumSponsorCard.module.scss';
import { useState, useEffect } from 'react';
import sponsorIcon from '../../assets/sponsorIcon.svg';
import { loadImage } from '../../utils/CommonUtils';

export default function PremiumSponsorCard({
  sponsorName,
  sponsorTag,
  sponsorIcon,
  sponsonLink,
}) {
  const [logo, setLogo] = useState(sponsorIcon);

  useEffect(() => {
    const logoURL = `${process.env.REACT_APP_IMG_BASE_URL}${sponsorIcon}/public`;
    loadImage(logoURL, sponsorIcon, setLogo);
  }, []);
  return (
    <a className={styles.premiumSponsorCard} href={sponsonLink || ''}>
      <div className={styles.txts}>
        <p>{sponsorName}</p>
        <span>{sponsorTag}</span>
      </div>
      <div className={styles.img}>
        <img src={logo} alt="sponsor icon" />
      </div>
    </a>
  );
}
